var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{staticClass:"page-pricings page-list-pricings",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Lista dei pricing")]},proxy:true},{key:"title-extra",fn:function(){return [_c('router-link',{staticClass:"btn btn-outline-light",attrs:{"to":{ name: 'pricings.new' }}},[_vm._v("Nuovo pricing")])]},proxy:true},{key:"default",fn:function(){return [(_vm.pricings && _vm.pricings.length > 0)?[_c('AwlTable',{attrs:{"heading":_vm.tableHeadings,"rows":_vm.pricings,"striped":""},scopedSlots:_vm._u([{key:"actions-content",fn:function(ref){
var row = ref.row;
return [_c('ActionShow',{attrs:{"to":{ name: 'pricings.show', params: { id: row.id } }}})]}},{key:"column-key-activation_price",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("currency")(value,false))+" €")]}},{key:"column-key-monthly_price",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("currency")(value,false))+" €")]}},{key:"column-key-fixed_fee",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("currency")(value,false))+" €")]}},{key:"column-key-percent_fee",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("percentage")(value))+" %")]}},{key:"column-key-enabled",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Sì' : 'No'))]}},{key:"column-key-listed",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Sì' : 'No'))]}},{key:"column-key-default",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Sì' : 'No'))]}}],null,false,3615911755)})]:_c('Alert',{attrs:{"status":"info"}},[_vm._v("Nessun pricing presente")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }