<template>
  <Page class="page-pricings page-list-pricings">
    <template #title>Lista dei pricing</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'pricings.new' }">Nuovo pricing</router-link>
    </template>

    <template #default>
      <template v-if="pricings && pricings.length > 0">
        <AwlTable :heading="tableHeadings" :rows="pricings" striped>
          <template #actions-content="{ row }">
            <ActionShow :to="{ name: 'pricings.show', params: { id: row.id } }" />
          </template>

          <template #column-key-activation_price="{ value }">{{ value | currency(false) }}&#160;€</template>
          <template #column-key-monthly_price="{ value }">{{ value | currency(false) }}&#160;€</template>
          <template #column-key-fixed_fee="{ value }">{{ value | currency(false) }}&#160;€</template>
          <template #column-key-percent_fee="{ value }">{{ value | percentage }}&#160;%</template>
          <template #column-key-enabled="{ value }">{{ value ? 'Sì' : 'No' }}</template>
          <template #column-key-listed="{ value }">{{ value ? 'Sì' : 'No' }}</template>
          <template #column-key-default="{ value }">{{ value ? 'Sì' : 'No' }}</template>
        </AwlTable>
      </template>
      <Alert v-else status="info">Nessun pricing presente</Alert>
    </template>
  </Page>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';
import formatPercentage from '@/filters/formatPercentage.js';
import formatCurrency from '@/filters/formatCurrency.js';

import Page from '@/views/components/Private/Page.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listPricings()
    .then(res => next(vm => {
      vm.pricings = res?.data?.pricings || [];
    }))
    .catch(() => next(from))
  ;
}

export default {
  mixins: [confirmWithModalMixin],
  filters: {
    percentage: formatPercentage,
    currency: formatCurrency,
  },
  components: {
    Page,
    AwlTable,
    ActionShow,
    Alert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  data: () => ({ pricings: [] }),
  computed: {
    tableHeadings () {
      return [
        { key: 'name', label: 'Nome' },
        { key: 'activation_price', label: 'Costo attivazione' },
        { key: 'monthly_price', label: 'Costo mensile' },
        { key: 'fixed_fee', label: 'Fee fissa' },
        { key: 'percent_fee', label: 'Fee percentuale' },
        { key: 'enabled', label: 'Attivabile' },
        { key: 'listed', label: 'Pubblico' },
        { key: 'default', label: 'Predefinito' },
        { key: 'activatingCoupon', label: 'Coupon di attivazione' },
      ];
    },
  },
  methods: {
    fetchResults () {
      return $api.listPricings()
        .then(res => {
          this.pricings = res?.data?.pricings || [];
        })
        .catch(() => this.$log.error)
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
